<template>
  <Dialog width-class="md:max-w-[510px]">
    <div id="product-request-dialog" class="overflow-auto h-fit">
      <div v-if="!session.isLoggedIn" class="m-sm md:m-md !mb-0">
        <Trans keypath="shop.dialog.productRequest.notLoggedInText">
          <span
            class="font-bold text-primary-base hover:cursor-pointer"
            @click="dialogStore.openDialog(DialogIdent.LOGIN)"
          >
            {{ t('shop.dialog.productRequest.loginNow') }}
          </span>
        </Trans>
        <div class="mt-md md:mt-xl">
          <ContentSeparator class="relative separator-overwrite" />
        </div>
      </div>

      <ClientOnly>
        <div
          id="product-request-form"
          :class="`${session.isLoggedIn ? '' : '!pt-0 '} p-sm md:p-md`"
        >
          <FormKit
            :id="formId"
            type="form"
            class="max-w-md"
            :actions="false"
            :incomplete-message="false"
            spellcheck="false"
            :config="{
              validationVisibility: 'blur',
              classes: {
                input:
                  useSiteIdent() !== SiteIdent.default ? 'rounded-corners' : '',
              },
            }"
            @submit="setContactData"
          >
            <div class="flex flex-row w-full flex-nowrap mb-sm">
              <p class="!mr-auto !ml-0 shrink-0 text-xl font-bold font-text">
                {{ t('shop.dialog.productRequest.myContactData') }}
              </p>
              <div
                class="w-full border-dashed border-b-1 border-border-light mb-2xs ml-2xs"
              ></div>
            </div>
            <TextInput
              id="officename"
              v-model="contactData.officename"
              name="officename"
              label="shop.dialog.productRequest.officename"
              :placeholder="t('shop.dialog.productRequest.officename')"
              :required-message="
                t('shop.dialog.productRequest.officename.required')
              "
              autofocus
            />

            <ValidationRow
              name="zipCity"
              combined-validation-message="shop.dialog.productRequest.zipRow.errorMsg"
              combined-messages-only
            >
              <TextInput
                v-model="contactData.zipCity.zip"
                name="zip"
                label="shop.dialog.productRequest.zip"
                placeholder="shop.dialog.productRequest.zip.placeholder"
                width="basis-3/12"
                :validation="zipCodeValidation"
              />
              <TextInput
                v-model="contactData.zipCity.city"
                name="city"
                label="shop.dialog.productRequest.city"
                placeholder="shop.dialog.productRequest.city"
                width="basis-9/12"
              />
            </ValidationRow>
            <TextInput
              id="productRequestOfficeTitle"
              v-model="contactData.title"
              name="title"
              label="shop.dialog.productRequest.title"
              placeholder="shop.dialog.productRequest.title.placeholder"
              :required="false"
            />
            <TextInput
              id="firstname"
              v-model="contactData.firstname"
              name="firstname"
              label="shop.dialog.productRequest.firstname"
              placeholder="shop.dialog.productRequest.firstname.placeholder"
              required-message="shop.dialog.productRequest.firstname.errorMsg"
            />
            <TextInput
              id="lastname"
              v-model="contactData.lastname"
              name="lastname"
              label="shop.dialog.productRequest.lastname"
              placeholder="shop.dialog.productRequest.lastname.placeholder"
              required-message="shop.dialog.productRequest.lastname.errorMsg"
            />
            <Email
              id="email"
              v-model="contactData.email"
              name="email"
              label="shop.dialog.productRequest.email"
              placeholder="shop.dialog.productRequest.email.placeholder"
              required-message="shop.dialog.productRequest.email.errorMsg"
              matches-message="shop.dialog.productRequest.email.errorMsg"
              required
            />

            <Checkbox
              :model-value="contactData.checkboxCallMe"
              class="mb-sm"
              label="shop.dialog.productRequest.callMe"
              name="checkboxCallMe"
            />

            <PhoneCanonical
              v-model="contactData.phone.fullPhoneNumber"
              :country-code="$languageCode.toUpperCase() as CountryCode"
              :required="contactData.checkboxCallMe"
            />

            <ButtonBasic
              classes="w-full px-sm md:px-0 pt-sm"
              :label="t('shop.dialog.productRequest.contactData.accept')"
              :type="EButtonType.SUBMIT"
              :btn-style="EButtonStyle.CTA"
              :loading-animation="isLoading"
              bg-color="var(--thm-btn-primary-base)"
              :disabled="isLoading"
              full-width
              @click="checkValidity()"
            ></ButtonBasic>
            <div
              class="flex items-center justify-center w-full gap-2 cursor-pointer text-primary-base mt-md"
              @click="dialogStore.closeDialog()"
            >
              <span class="anim__underline-link anim-link">
                {{ t('formFields.form.cancel') }}
              </span>
            </div>
          </FormKit>
        </div>
      </ClientOnly>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { useDialogStore, DialogIdent } from '~/stores/useDialogStore';
import { EButtonStyle, EButtonType } from '~~/src/@types/basic-button';
import ButtonBasic from '~~/src/components/components/core/basic-button/basic-button.vue';
import Trans from '@/components/Trans.vue';
import ContentSeparator from '@/components/block/seperator/contentSeparator.vue';
import { TextInput, Checkbox, Email, PhoneCanonical } from '@/complib';
import ValidationRow from '@/components/formFields/validationRow/validationRow.vue';
import {
  type ProductRequestContactData,
  useProductRequestContactData,
} from './useProductRequestDialog';
import { useZipCodeValidation } from '~/components/formFields/composables/useZipCodeValidation';
import type { CountryCode } from '~/components/formFields/types';
import { SiteIdent } from '~/utils/types';

const formId = 'productRequest-contactData';
const zipCodeValidation = useZipCodeValidation();

const { t } = useTrans();
const dialogStore = useDialogStore();
dialogStore.setHeadline(t('shop.tile.product.productRequest'));
const { isLoading, contactData, updateContactData, session } =
  await useProductRequestContactData();

const checkValidity = async () => {
  const form = document.getElementById(formId);
  if (!form) return;

  // Sleep for quarter second till form is updated with validation information
  await new Promise((r) => setTimeout(r, 250));
  const invalidElements = form.querySelectorAll('[data-invalid=true]');

  if (!invalidElements.length) return;

  invalidElements[0].scrollIntoView({
    behavior: 'smooth',
  });
};

const setContactData = async (data: ProductRequestContactData) => {
  (document.activeElement as HTMLElement)?.blur();
  updateContactData(data);
  dialogStore.closeDialog();
};
</script>
<style lang="postcss">
#product-request-dialog {
  :deep(.separator-overwrite .separator) {
    @apply !pb-0;
  }
  #product-request-form {
    .formkit-inner > input,
    .formkit-inner > div,
    .formkit-input {
      @apply w-full;
    }

    .formkit-outer .rounded-corners {
      @apply rounded-[6px];
    }

    .formkit-outer:has(> .formkit-messages) {
      .rounded-corners {
        @apply rounded-b-none;
      }

      .formkit-inner > * {
        @apply !border-b-status-danger-base;
      }
    }

    .formkit-outer:has(> .formkit-messages) {
      @apply rounded-b-none;
    }

    .formkit-form .custom-button {
      @apply w-full justify-center !mx-0 !px-4;
    }

    .formkit-outer .formkit-messages,
    .formkit-messages-custom {
      @apply !bg-[rgba(255,255,255,.6)] rounded-b pl-[9px] py-[2px] text-sm leading-xs text-status-danger-base;
    }

    .formkit-outer .formkit-message {
      @apply !text-status-danger-base text-sm leading-xs;
    }

    .form-popup {
      @apply absolute flex justify-end h-0 top-[10px] right-4;
    }

    .form-custom-popper {
      --popper-theme-background-color: #5c8a8e !important;
      --popper-theme-background-color-hover: #5c8a8e !important;
    }
  }
}
</style>
